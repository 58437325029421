import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorPage } from '@screentone/addon-auth-wrapper';

import { ResponsiveLoader } from 'components';
import { AddEntity } from 'components/context-menu-actions/add-entity/AddEntity';
import { CopyPublishedURL } from 'components/context-menu-actions/copy-published-url/CopyPublishedURL';
import { EditAltSumm } from 'components/context-menu-actions/edit-alt-summ/EditAltSumm';
import { ExcludeFromQuery } from 'components/context-menu-actions/exclude-from-query/ExcludeFromQuery';
import { ExternalLinkContentType } from 'components/context-menu-actions/external-link/ExternalLink';
import { MoveManualArticleToQueryArticle } from 'components/context-menu-actions/move-manual-article-to-query-article/MoveManualArticleToQueryArticle';
import { MoveQueryArticleToManualArticle } from 'components/context-menu-actions/move-query-article-to-manual-article/MoveQueryArticleToManualArticle';
import { OpenInNewsGrid } from 'components/context-menu-actions/open-in-newsgrid/OpenInNewsGrid';
import { OpenInNewsPress } from 'components/context-menu-actions/open-in-newspress/OpenInNewsPress';
import { RemoveEntity } from 'components/context-menu-actions/remove-entity/RemoveEntity';
import { ViewPublishedURL } from 'components/context-menu-actions/view-published-url/ViewPublishedURL';
import ExternalLink from 'components/external-link/ExternalLink';
import { useAlert } from 'contexts/alert/useAlert';
import {
  ContextMenuActionsProvider,
  MenuActions,
  MenuActionsKeys
} from 'contexts/context-menu-actions/ContextMenuActionsContext';
import { DataModelProvider } from 'contexts/datamodel/DataModelContext';
import { DragAndDropProvider } from 'contexts/drag-and-drop/DragAndDropContext';
import { SummarianProvider } from 'contexts/summarian/SummarianContext';
import { TrashProvider } from 'contexts/trash/TrashContext';
import {
  ArticleItem,
  CollectionDto,
  useCollectionDtoByIdQuery,
  useCollectionDtoCreateMutation
} from 'data/generated/graphql';
import { CollectionEdit } from 'features/collection-edit/CollectionEdit';
import { useConvertedProperty } from 'hooks';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { safelyParseContent } from 'utils/temp';

const CollectionEditPage = () => {
  const { idCreatedUtc } = useParams();
  const currentProperty = useConvertedProperty();
  const [fetchError, setFetchError] = useState<string>('');
  const [collectionDTO, setCollectionDTO] = useState<CollectionDto | null>(null);
  const allessehId = idCreatedUtc?.replace(/_\d{13}/, '');
  const { mutateAsync: collectionCreateMutationAsync, isLoading: isCreating } = useCollectionDtoCreateMutation();
  const { alertError } = useAlert();
  const navigate = useNavigate();
  const isNewCollection = idCreatedUtc === 'new';

  const contextActions = useMemo<MenuActions>(
    () => ({
      [MenuActionsKeys.DraftArticle]: [
        {
          component: ({ hierarchyId }) => <RemoveEntity hierarchyId={hierarchyId!} text="Remove from collection" />,
          showIf: ({ extraProperties }) => extraProperties!.isContentIdExcludedInQueryRules === false,
          withDivider: 'bottom'
        },
        {
          component: ({ hierarchyId, extraProperties }) => (
            <MoveManualArticleToQueryArticle
              hierarchyId={hierarchyId!}
              jsonQueryStr={extraProperties!.jsonQueryStr!}
              allessehContent={extraProperties!.allessehContent!}
              setJsonQueryStr={extraProperties!.setJsonQueryStr!}
            />
          ),
          showIf: ({ extraProperties }) => extraProperties!.isContentIdExcludedInQueryRules === true,
          withDivider: 'bottom'
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <EditAltSumm content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsPress content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsGrid content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <ViewPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
          },
          showIf: ({ entity }) => {
            const article = entity as ArticleItem;
            return article.attributes.product !== 'YDJ';
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <CopyPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
          },
          showIf: ({ entity }) => {
            const article = entity as ArticleItem;
            return article.attributes.product !== 'YDJ';
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            const content = safelyParseContent<AllessehContent>(article.content);

            return (
              <ExternalLink
                contentId={content.data.id}
                isPublished={!!content.data.attributes.published_datetime}
                type={ExternalLinkContentType.ARTICLE}
                embargo={content.data.attributes.content_status === 'embargo'}
              />
            );
          }
        }
      ],
      [MenuActionsKeys.CollectionDraftQueryArticle]: [
        {
          component: ({ entity, hierarchyId, extraProperties }) => (
            <MoveQueryArticleToManualArticle
              article={entity as ArticleItem}
              hierarchyId={hierarchyId!}
              jsonQueryStr={extraProperties!.jsonQueryStr!}
              allessehContent={extraProperties!.allessehContent!}
              setJsonQueryStr={extraProperties!.setJsonQueryStr!}
            />
          )
        },
        {
          component: ({ extraProperties }) => (
            <ExcludeFromQuery
              jsonQueryStr={extraProperties!.jsonQueryStr!}
              allessehContent={extraProperties!.allessehContent!}
              setJsonQueryStr={extraProperties!.setJsonQueryStr!}
            />
          ),
          withDivider: 'bottom'
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <EditAltSumm content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsPress content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsGrid content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <ViewPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
          },
          showIf: ({ entity }) => {
            const article = entity as ArticleItem;
            return article.attributes.product !== 'YDJ';
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <CopyPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
          },
          showIf: ({ entity }) => {
            const article = entity as ArticleItem;
            return article.attributes.product !== 'YDJ';
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            const content = safelyParseContent<AllessehContent>(article.content);

            return (
              <ExternalLink
                contentId={content.data.id}
                isPublished={!!content.data.attributes.published_datetime}
                type={ExternalLinkContentType.ARTICLE}
                embargo={content.data.attributes.content_status === 'embargo'}
              />
            );
          }
        }
      ],
      [MenuActionsKeys.ArticleSearch]: [
        {
          component: ({ hierarchyId, entity }) => <AddEntity entity={entity!} hierarchyId={hierarchyId!} />,
          withDivider: 'bottom'
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <EditAltSumm content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsPress content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <OpenInNewsGrid content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <ViewPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
          },
          showIf: ({ entity }) => {
            const article = entity as ArticleItem;
            return article.attributes.product !== 'YDJ';
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <CopyPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
          },
          showIf: ({ entity }) => {
            const article = entity as ArticleItem;
            return article.attributes.product !== 'YDJ';
          }
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            const content = safelyParseContent<AllessehContent>(article.content);

            return (
              <ExternalLink
                contentId={content.data.id}
                isPublished={!!content.data.attributes.published_datetime}
                type={ExternalLinkContentType.ARTICLE}
                embargo={content.data.attributes.content_status === 'embargo'}
              />
            );
          }
        }
      ]
    }),
    []
  );

  const {
    data,
    isLoading,
    isFetching,
    error: loadingError
  } = useCollectionDtoByIdQuery(
    { id: allessehId!, publicationKey: currentProperty ?? '' },
    { enabled: !isNewCollection && !!allessehId && !!currentProperty }
  );

  const isCollectionDTOLoading = !collectionDTO || (isNewCollection ? isCreating : isLoading || isFetching);

  const createCollection = async (publicationKey: string) => {
    const collection = await collectionCreateMutationAsync({
      collectionDTOCreateInput: {
        name: 'Unnamed Page Section Collection',
        publicationKey
      }
    });
    setCollectionDTO(collection.collectionDTOCreate as CollectionDto);
  };

  useEffect(() => {
    if (isCollectionDTOLoading) {
      setCollectionDTO(null);
    }
  }, [isCollectionDTOLoading]);

  useEffect(() => {
    if (isNewCollection && currentProperty) {
      createCollection(currentProperty).catch(() => {
        alertError('Could not create a collection. Please try again.');
        navigate(`/${currentProperty}/collections`);
      });
    }
  }, [currentProperty]);

  useEffect(() => {
    if (loadingError) {
      console.error('Fetch error', loadingError);
      let error = '404';
      if (loadingError instanceof Error) {
        if (loadingError.message === 'Failed to fetch') {
          error = '503';
        }
      }
      setFetchError(error);
    }
  }, [loadingError]);

  useEffect(() => {
    if (data?.collectionDTOById) {
      setCollectionDTO(data.collectionDTOById as CollectionDto);
    }
  }, [data]);

  if (fetchError) {
    return <ErrorPage type={String(fetchError)} />;
  }

  return (
    <>
      {isCollectionDTOLoading ? (
        <ResponsiveLoader />
      ) : (
        <SummarianProvider>
          <DataModelProvider type="CollectionDTO" root={collectionDTO.root} metadata={collectionDTO.metadata}>
            <TrashProvider>
              <DragAndDropProvider>
                <ContextMenuActionsProvider actions={contextActions}>
                  <CollectionEdit />
                </ContextMenuActionsProvider>
              </DragAndDropProvider>
            </TrashProvider>
          </DataModelProvider>
        </SummarianProvider>
      )}
    </>
  );
};

export default CollectionEditPage;
