import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Group, IconList, Overlay, Typography, useModalPortal, Wrapper } from '@screentone/core';
import { cloneDeep } from 'lodash';

import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { useItp } from 'contexts/itp/useItp';
import { useSummarianContext } from 'contexts/summarian/useSummarianContext';
import { ItpDto, ItpIssueStatus } from 'data/generated/graphql';
import { useMediaForm } from 'hooks/altsumm/useMediaForm';
import { useMediaPreview } from 'hooks/altsumm/useMediaPreview';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { SnippetyChartData, SnippetyResponseWithImage, VideoDataForSummarian } from 'hooks/useSnippetyQuery';
import { arrayEquals } from 'utils/array';
import { decodeHtmlEntities, encodeHtmlEntities } from 'utils/text';
import { formatBulletLink } from 'utils/url';
import styles from './AltSummModal.module.scss';
import {
  ControlledAltSumm,
  DEFAULT_CONTROLLED_ALT_SUMM,
  DEFAULT_MEDIA_ALT_SUMM,
  massageControlledAltSummBeforeSendingToSummarian
} from './AltSummModal.utils';
import { BulletInput } from './components/bullet-input/BulletInput';
import { MediaInputButton } from './components/media-input/components/MediaInputButton';
import { MediaInputForm } from './components/media-input/components/MediaInputForm';
import { MediaInputPreview } from './components/media-input/components/MediaInputPreview';
import { MediaTypes } from './components/media-input/MediaInput.utils';

interface AltSumModalProps {
  setIsModalOpenControlled?: (isOpen: boolean) => void;
  isModalOpenControlled?: boolean;
  content: AllessehContent;
}

export const AltSummModal = ({ content, setIsModalOpenControlled, isModalOpenControlled }: AltSumModalProps) => {
  const { isModalOpen, setIsModalOpen } = useContextMenuActions();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const { renderNode } = useModalPortal() as { renderNode: React.ReactNode };
  const { data: publicationSettingsResp } = usePublicationSettings();

  const [controlledAltSum, setControlledAltSum] = useState<ControlledAltSumm>(DEFAULT_CONTROLLED_ALT_SUMM);
  const [isUpdating, setIsUpdating] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const [inputType, setInputType] = useState<MediaTypes | ''>('');

  const headlineRef = useRef<HTMLTextAreaElement>(null);
  const bodyRef = useRef<HTMLTextAreaElement>(null);

  const publicationSettings = publicationSettingsResp?.publicationSetting;
  const imagePreviewRef = useRef<HTMLImageElement>(null);

  const handleChangeForImage = useCallback((imageData: SnippetyResponseWithImage | null) => {
    const imageUrl = imageData ? imageData.tools.image.href : '';
    setControlledAltSum((prev) => ({
      ...prev,
      ...DEFAULT_MEDIA_ALT_SUMM,
      image: imageData ? [imageData.tools.image] : [],
      imageUrl
    }));
    setPreviewUrl(imageUrl);
  }, []);

  const handleChangeForVideo = useCallback(
    (videoData: VideoDataForSummarian | null) => {
      const videoUrl = (videoData?.guid && `${publicationSettings?.snippetyVideoDomain}${videoData.guid}`) ?? '';
      setControlledAltSum((prev) => ({
        ...prev,
        ...DEFAULT_MEDIA_ALT_SUMM,
        video: videoData ? [videoData] : [],
        videoUrl,
        videoImage: videoData?.sizes!.full.url ?? ''
      }));
      setPreviewUrl(videoUrl);
    },
    [publicationSettings]
  );

  const handleChangeForChart = useCallback((chartData: SnippetyChartData | null) => {
    const chartUrl = chartData?.media ?? '';
    setControlledAltSum((prev) => ({
      ...prev,
      ...DEFAULT_MEDIA_ALT_SUMM,
      chart: chartUrl,
      chartlosId: (chartData?.id && `cdc_${chartData.id}`) ?? ''
    }));
    setPreviewUrl(chartUrl);
  }, []);

  const handleUpdateBullets = useCallback((bullets: string[]) => {
    setControlledAltSum((prev: ControlledAltSumm) => ({
      ...prev,
      bullets
    }));
  }, []);

  const handleIdAdd = useCallback(
    (data: SnippetyResponseWithImage | VideoDataForSummarian | SnippetyChartData | null) => {
      if (inputType === 'image') {
        handleChangeForImage(data as SnippetyResponseWithImage);
      } else if (inputType === 'video') {
        handleChangeForVideo(data as VideoDataForSummarian);
      } else if (inputType === 'chart') {
        handleChangeForChart(data as SnippetyChartData);
      } else if (!data) {
        setControlledAltSum((prev) => ({
          ...prev,
          ...DEFAULT_MEDIA_ALT_SUMM
        }));
        setPreviewUrl('');
      }
    },
    [handleChangeForChart, handleChangeForImage, handleChangeForVideo, inputType]
  );

  const { id, error, onDelete, onIdAdd, onIdChange, shouldAdd, isIdFormOpen, setIsIdFormOpen } = useMediaForm(
    inputType,
    handleIdAdd,
    setControlledAltSum,
    publicationSettings
  );

  const { onImageLoad, clearPreview, onResetPreview, isMediaLoaded, computedAspectRatio, previewType } =
    useMediaPreview({
      setIsIdFormOpen,
      setPreviewUrl,
      imagePreviewRef,
      imageUrl: controlledAltSum.imageUrl,
      videoUrl: controlledAltSum.videoUrl,
      chartUrl: controlledAltSum.chart,
      setInputType,
      setControlledAltSum
    });

  const { root } = useDataModelContext();
  const { setHasAltSummChanged, updateIssue } = useItp();
  const {
    selectedAltSummVariant: altSummVariant,
    trackContent,
    getAltSummFields,
    setAltSummFields
  } = useSummarianContext();

  const [displayNewBulletInput, setDisplayNewBulletInput] = useState(false);
  const [newBullet, setNewBullet] = useState('');

  const { headline, body, image, video, bullets, chartlosId, chart, subhead, proxy, videoImage } =
    getAltSummFields(content);

  const itpRoot = root as ItpDto['root'] | null;

  useEffect(() => {
    trackContent(content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackContent]);

  useEffect(() => {
    if (headlineRef.current) {
      headlineRef.current.style.height = '0';
      headlineRef.current.style.height = `${headlineRef.current.scrollHeight}px`;
    }
    if (bodyRef.current) {
      bodyRef.current.style.height = '0';
      bodyRef.current.style.height = `${bodyRef.current.scrollHeight}px`;
    }
  }, [controlledAltSum.headline, controlledAltSum.body]);

  useEffect(() => {
    setControlledAltSum((prev) => ({ ...prev, headline }));
  }, [headline]);

  useEffect(() => {
    setControlledAltSum((prev) => ({ ...prev, body }));
  }, [body]);

  useEffect(() => {
    setControlledAltSum((prev) => ({ ...prev, imageUrl: image }));
  }, [image]);

  useEffect(() => {
    setControlledAltSum((prev) => ({ ...prev, videoUrl: video }));
  }, [video]);

  useEffect(() => {
    setControlledAltSum((prev) => ({ ...prev, videoImage: videoImage ?? '' }));
  }, [videoImage]);

  useEffect(() => {
    setControlledAltSum((prev) => ({ ...prev, bullets }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(bullets)]);

  useEffect(() => {
    setControlledAltSum((prev) => ({ ...prev, subhead }));
  }, [subhead]);

  useEffect(() => {
    setControlledAltSum((prev) => ({ ...prev, chartlosId }));
  }, [chartlosId]);

  useEffect(() => {
    setControlledAltSum((prev) => ({ ...prev, chart }));
  }, [chart]);

  const handleToggleModal = () => {
    if (!setIsModalOpenControlled) {
      setIsModalOpen(!isModalOpen);
    } else {
      setIsModalOpenControlled(!isModalOpenControlled);
    }
  };
  const handleCancel = () => handleToggleModal();

  const handleChangeForTextFields =
    (field: keyof typeof controlledAltSum) => (inputEvent: React.ChangeEvent<HTMLTextAreaElement>) => {
      setControlledAltSum((prev) => ({ ...prev, [field]: inputEvent.target.value }));
    };

  const updateAltSumThroughSummarian = async () => {
    /* eslint-disable @typescript-eslint/await-thenable */
    if (!proxy) return;
    const headlinePersistedValue = await proxy.headline;

    if (controlledAltSum.headline !== headlinePersistedValue) {
      proxy.headline = encodeHtmlEntities(controlledAltSum.headline);
      await proxy.headline;
    }

    const bodyPersistedValue = await proxy.body;
    if (controlledAltSum.body !== bodyPersistedValue) {
      proxy.body = encodeHtmlEntities(controlledAltSum.body);
      await proxy.body;
    }

    const imagePersistedValue = await proxy.images;
    if (controlledAltSum.imageUrl !== imagePersistedValue?.[0]?.href) {
      proxy.images = controlledAltSum.image;
      await proxy.images;
    }

    const videoPersistedValue = await proxy.videos;
    const videoId = controlledAltSum.videoUrl.split('?guid=')[1];
    if (videoId !== videoPersistedValue?.[0]?.guid) {
      proxy.videos = controlledAltSum.video;
      await proxy.videos;
    }

    const videoImagePersistedValue = (await proxy.videoImage) as string;
    if (video && controlledAltSum.video[0]?.sizes?.full.url !== videoImagePersistedValue) {
      proxy.videoImage = controlledAltSum.videoImage;
      await proxy.videoImage;
    }

    const chartlosIdPersistedValue = await proxy.chartlosId;
    if (controlledAltSum.chartlosId !== chartlosIdPersistedValue) {
      proxy.chartlosId = controlledAltSum.chartlosId;
      await proxy.chartlosId;
    }

    const bulletsPersistedValue = await proxy.bullets;
    if (!arrayEquals(controlledAltSum.bullets, [...(bulletsPersistedValue || [])])) {
      const escapedBullets = controlledAltSum.bullets.map((bullet) => {
        const scriptTagRegex = /<a[^>]*href=["']([^"']*)["']/g;
        const headlineRegex = /<a[^>]*href=["'][^"']*["']>([^<]*)<\/a>/g;
        const bulletLink = scriptTagRegex.exec(bullet);
        const bulletHeadline = headlineRegex.exec(bullet);
        const link = bullet.split('">')[0].slice(9);
        const headline = bullet.split('">')[1].slice(0, -4);
        if (bulletLink && bulletHeadline) {
          return formatBulletLink(encodeHtmlEntities(bulletLink[1]), encodeHtmlEntities(bulletHeadline[1]));
        }
        return formatBulletLink(encodeHtmlEntities(link), encodeHtmlEntities(headline));
      });
      proxy.bullets = escapedBullets;
      await proxy.bullets;
    }

    const subheadPersistedValue = await proxy.subhead;
    if (controlledAltSum.subhead !== subheadPersistedValue) {
      proxy.subhead = controlledAltSum.subhead;
      await proxy.subhead;
    }

    const version = await window.summarianShareDbAPI.currentTemplateVersion(content.data.id, altSummVariant);
    await window.summarianShareDbAPI.publishTemplateAndContent(content.data.id, altSummVariant, version);
    setControlledAltSum(controlledAltSum);
    setAltSummFields(content, massageControlledAltSummBeforeSendingToSummarian(controlledAltSum));
    /* eslint-enable @typescript-eslint/await-thenable */
  };

  return (
    <Overlay data-testid="alt-summ-modal-view" onDismiss={handleToggleModal} status="open" renderNode={renderNode}>
      <Box data-testid="alt-summ-modal-container" className={styles.modalBox}>
        <Box.Title data-testid="alt-summ-modal-title">Edit Alt-Summ</Box.Title>
        <Box.Content data-testid="alt-summ-modal-content" padding={{ all: 'none' }}>
          <Wrapper data-testid="alt-summ-modal-body" padding={{ top: 'md', right: 'mlg', bottom: 'md', left: 'mlg' }}>
            <Group className={styles.groupsWrapper}>
              <Group.Item flex>
                <Box>
                  <Box.Content padding={{ all: 'md' }} className={styles.dynamicContentBox}>
                    <Typography data-testid="alt-summ-modal-type" variant="header4">
                      {altSummVariant}
                    </Typography>
                    {previewUrl && (
                      <MediaInputPreview
                        computedAspectRatio={computedAspectRatio}
                        onDelete={clearPreview}
                        onResetPreview={onResetPreview}
                        previewUrl={previewUrl}
                        controlledAltSum={controlledAltSum}
                        onUrlLoaded={onImageLoad}
                        isUrlLoaded={isMediaLoaded}
                        imgRef={imagePreviewRef}
                        previewType={previewType}
                        isVisible={!isIdFormOpen}
                      />
                    )}
                    <MediaInputForm
                      onIdChange={onIdChange}
                      onDelete={onDelete}
                      id={id}
                      error={error}
                      onIdAdd={onIdAdd}
                      isIdFormOpen={!!isIdFormOpen}
                      setIsIdFormOpen={setIsIdFormOpen}
                      shouldAdd={shouldAdd}
                      setVariant={setInputType}
                      inputType={inputType}
                      setControlledAltSum={setControlledAltSum}
                    />
                    <Wrapper data-testid="alt-summ-modal-form" className={styles.inputsWrapper} margin={{ top: 'md' }}>
                      <textarea
                        data-testid="alt-summ-modal-headline-input"
                        className={styles.headlineTextarea}
                        onChange={handleChangeForTextFields('headline')}
                        value={decodeHtmlEntities(controlledAltSum.headline)}
                        ref={headlineRef}
                        placeholder="Alt-summ headline"
                      />
                      <span data-testid="alt-summ-modal-headline-counter" className={styles.headlineCharCount}>
                        {controlledAltSum.headline.length}
                      </span>
                      <textarea
                        data-testid="alt-summ-modal-body-input"
                        className={styles.bodyTextarea}
                        onChange={handleChangeForTextFields('body')}
                        value={decodeHtmlEntities(controlledAltSum.body)}
                        ref={bodyRef}
                        placeholder="Alt-summ body"
                      />
                    </Wrapper>
                    <BulletInput
                      bullets={controlledAltSum.bullets}
                      handleUpdateBullets={handleUpdateBullets}
                      setDisplayNewBulletInput={setDisplayNewBulletInput}
                      displayNewBulletInput={displayNewBulletInput}
                    />
                  </Box.Content>
                </Box>
              </Group.Item>
              <Group.Item data-testid="alt-summ-modal-form" className={styles.groupsItem} flex>
                <Wrapper>
                  <MediaInputButton
                    variant="image"
                    setVariant={setInputType}
                    isIdFormOpen={!!isIdFormOpen}
                    setIsIdFormOpen={setIsIdFormOpen}
                    disabled={!!previewUrl || inputType === 'video' || inputType === 'chart'}
                  />
                  <MediaInputButton
                    variant="video"
                    setVariant={setInputType}
                    isIdFormOpen={!!isIdFormOpen}
                    setIsIdFormOpen={setIsIdFormOpen}
                    disabled={!!previewUrl || inputType === 'image' || inputType === 'chart'}
                  />
                  <MediaInputButton
                    variant="chart"
                    setVariant={setInputType}
                    isIdFormOpen={!!isIdFormOpen}
                    setIsIdFormOpen={setIsIdFormOpen}
                    disabled={!!previewUrl || inputType === 'image' || inputType === 'video'}
                  />
                  <Button
                    data-testid="media-bullet-button"
                    onClick={() => setDisplayNewBulletInput(true)}
                    tertiary
                    icon={IconList as SvgComponent}
                  >
                    Add Bullet
                  </Button>
                </Wrapper>
              </Group.Item>
            </Group>
          </Wrapper>
          <Wrapper data-testid="alt-summ-modal-footer" padding={{ top: 'sm', right: 'mlg', bottom: 'md', left: 'mlg' }}>
            <Group data-testid="alt-summ-modal-buttons" gap="sm" className={styles.footerButtonsWrapper} align="end">
              <Button data-testid="alt-summ-modal-cancel-button" secondary onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                data-testid="alt-summ-modal-update-button"
                primary
                disabled={isUpdating || isIdFormOpen}
                onClick={async () => {
                  setIsUpdating(true);
                  if (newBullet.length) {
                    const bulletLink = `<a href="${newBullet}"></a>`;
                    setControlledAltSum((prev) => ({ ...prev, bullets: [...prev.bullets, bulletLink] }));
                    setNewBullet('');
                    setDisplayNewBulletInput(false);
                  }

                  await updateAltSumThroughSummarian();

                  if (itpRoot?.type === 'Issue') {
                    const updatedRoot = cloneDeep(itpRoot);

                    if ((itpRoot.attributes.status as ItpIssueStatus) === ItpIssueStatus.Published) {
                      updatedRoot.attributes.status = ItpIssueStatus.Changed;
                    }

                    updateIssue({ root: updatedRoot });
                    setHasAltSummChanged(true);
                  }

                  if (!setIsModalOpenControlled) {
                    setIsModalOpen(!isModalOpen);
                  } else {
                    setIsModalOpenControlled(!isModalOpenControlled);
                  }
                  setIsUpdating(false);
                }}
              >
                Apply
              </Button>
            </Group>
          </Wrapper>
        </Box.Content>
      </Box>
    </Overlay>
  );
};
