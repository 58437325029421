/* eslint-disable import/no-cycle */
import { useCallback, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Dropdown, Group, IconThreeDotsVer, Typography, Wrapper } from '@screentone/core';
import classnames from 'classnames';

import { CommonEntityProps } from 'components/datamodel/commonEntityProps';
import { useAlert } from 'contexts/alert/useAlert';
import { MenuActionsKeys } from 'contexts/context-menu-actions/ContextMenuActionsContext';
import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { DataModelType, EntityType } from 'contexts/datamodel/DataModelContext';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { DROPPABLE_ID_PREFIXES } from 'contexts/drag-and-drop/dragUtils';
import { usePageTypeSettingsContext } from 'contexts/page-type-settings/usePageTypeSettings';
import {
  ArticleItem,
  ExternalCollectionItem,
  ModuleContainer,
  PageModule,
  QueryItem,
  UiModuleType
} from 'data/generated/graphql';
import { AddModuleButton } from 'features/section-page-edit/components/add-module-button/AddModuleButton';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { safelyParseContent } from 'utils/temp';
import { ModuleItemCount } from './components/module-item-count/ModuleItemCount';
import { TreatmentTypeDropdown } from './components/treatment-type-dropdown/TreatmentTypeDropdown';
import { UiModuleFieldsHeader } from './components/ui-module-fields-header/UiModuleFieldsHeader';
import styles from './Module.module.scss';

interface ModuleProps extends CommonEntityProps {
  data: ModuleContainer;
}

export const Module = ({ data: module, parentHierarchyId, index, isHistory }: ModuleProps) => {
  const { type, generateHierarchyId, renderEntity, readHierarchyId } = useDataModelContext();
  const { renderActions: renderMenuActions, hasRenderActions: hasMenuActions } = useContextMenuActions();
  const hierarchyId = generateHierarchyId(module, parentHierarchyId, index);

  const { moduleHasValidationError } = useAlert();
  const { getTreatmentTypeSetting, currentLayout, addableUiModules, pageTypeSetting } = usePageTypeSettingsContext();

  const pageModule = module.attributes.pageModule as PageModule;
  const treatmentTypeSetting = getTreatmentTypeSetting(pageModule);

  const hasValidationError = !isHistory && moduleHasValidationError(hierarchyId);

  const [articlesPerContentItem, setArticlesPerContentItem] = useState<number[]>(
    module.collection.map((contentItem) => {
      if (contentItem.type === 'Article') {
        return 1;
      }
      if (contentItem.type === 'Collection') {
        const item = contentItem as ExternalCollectionItem;
        return item.contentItems.length;
      }
      if (contentItem.type === 'Query') {
        const item = contentItem as QueryItem;
        if (item.attributes.isFeed) {
          return 1;
        }
        return 0;
      }

      return 0;
    })
  );

  const handleContentLoad = useCallback((index: number, contentLength: number) => {
    setArticlesPerContentItem((prev) => {
      const newLength = [...prev];
      newLength[index] = contentLength;
      return newLength;
    });
  }, []);

  const getNumTotalItemsBeforeIndex = (index: number) =>
    articlesPerContentItem.reduce((acc: number, curr: number, numContentItemsIndex: number) => {
      if (numContentItemsIndex < index) {
        const item = module.collection[numContentItemsIndex];
        if (item.type === 'Article') {
          const articleItem = item as ArticleItem;
          const parsedContent = safelyParseContent<AllessehContent>(articleItem.content);
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (!parsedContent.data?.attributes?.content_status) {
            return acc + curr;
          }
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          const itemStatus = parsedContent.data?.attributes?.content_status;
          return itemStatus === 'embargo' ? acc : acc + curr;
        }
        if (item.type === 'Query') {
          const queryItem = item as QueryItem;
          if (queryItem.attributes.isFeed) {
            return acc + curr;
          }
        }
      }
      return numContentItemsIndex < index ? acc + curr : acc;
    }, 0);

  const numTotalItemsUsed = getNumTotalItemsBeforeIndex(module.collection.length);

  const droppablePrefix = isHistory ? DROPPABLE_ID_PREFIXES.HISTORY : DROPPABLE_ID_PREFIXES.MODULE_ITEMS;
  const actionsType = isHistory ? MenuActionsKeys.HistoryModule : MenuActionsKeys.DraftModule;

  const isAvailableLayoutModuleType =
    currentLayout?.availableLayoutModules.map((m) => m.uiModuleType).includes(pageModule.uiModuleType) ?? false;

  const isFeedModule = pageModule.uiModuleType === UiModuleType.UiFeedsModuleType;
  const isITP = type === DataModelType.ItpDTO;

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const isDropDisabled = isHistory || isFeedModule;

  const itpModuleIndex = readHierarchyId(hierarchyId).find((h) => h.entity === EntityType.Page)?.index;

  return (
    <Wrapper
      margin={{ bottom: !isAvailableLayoutModuleType || isHistory ? 'mlg' : 'none' }}
      data-model-hierarchy-id={hierarchyId}
      className={styles.moduleWrapper}
    >
      <Group margin={{ bottom: 'xs' }} align="space-between">
        <Group gap="none" data-testid="page-treatment-header">
          {isITP && typeof itpModuleIndex === 'number' && (
            <Typography size="sm" weight="bold" margin={{ right: 'sm' }} data-testid="page-treatment-type">
              {`Page ${Number(itpModuleIndex) + 1}`}
            </Typography>
          )}
          <TreatmentTypeDropdown
            hierarchyId={hierarchyId}
            module={module}
            pageModule={pageModule}
            pageTypeSetting={pageTypeSetting}
            showCurrentTreatmentTypeAtTheTop
            viewOnly={isHistory}
          />
        </Group>
        {hasMenuActions(actionsType, {
          entity: module,
          hierarchyId,
          index,
          extraProperties: { numTotalItemsUsed }
        }) && (
          <Dropdown
            padding={{ all: 'none' }}
            position="right"
            trigger={<IconThreeDotsVer color="asphalt" />}
            data-testid="page-draft-ellipsis-icon"
          >
            {renderMenuActions(actionsType, {
              entity: module,
              hierarchyId,
              index,
              extraProperties: {
                maxAllowedItems: treatmentTypeSetting?.maxRequiredContentItems ?? 0,
                numTotalItemsUsed
              }
            })}
          </Dropdown>
        )}
      </Group>
      <UiModuleFieldsHeader pageModule={pageModule} />
      <Wrapper
        padding={{ all: 'xs' }}
        className={classnames(
          styles.moduleBox,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          isFeedModule ? styles.moduleFeedsArea : styles.moduleDashedArea
        )}
        data-error={hasValidationError ? 'true' : 'false'}
      >
        <Droppable droppableId={droppablePrefix + hierarchyId} isDropDisabled={isDropDisabled}>
          {(droppableProvided) => (
            <div
              data-testid="page-module-item"
              className={styles.moduleDroppableArea}
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {module.collection.map((item, i) => {
                const availableContentSlots = treatmentTypeSetting?.maxRequiredContentItems
                  ? treatmentTypeSetting.maxRequiredContentItems - getNumTotalItemsBeforeIndex(i)
                  : treatmentTypeSetting?.minRequiredContentItems
                  ? treatmentTypeSetting.minRequiredContentItems - getNumTotalItemsBeforeIndex(i)
                  : undefined;
                const articleHierarchyId = generateHierarchyId(item, hierarchyId, i);
                const articleActionsType = isHistory ? MenuActionsKeys.HistoryArticle : MenuActionsKeys.DraftArticle;

                return (
                  <div key={i}>
                    {renderEntity(item, {
                      hierarchyId,
                      index: i,
                      isHistory,
                      extraProps: {
                        content: { availableContentSlots, onContentLoaded: handleContentLoad, isDraft: !isHistory },
                        article: {
                          renderActions: () =>
                            renderMenuActions(articleActionsType, {
                              hierarchyId: articleHierarchyId,
                              entity: item,
                              index: i
                            }),
                          isOverflow: isITP && i >= (treatmentTypeSetting?.maxRequiredContentItems ?? 0)
                        },
                        module: {
                          treatmentTypeSetting
                        }
                      }
                    })}
                  </div>
                );
              })}
              {droppableProvided.placeholder}
              <ModuleItemCount
                min={treatmentTypeSetting?.minRequiredContentItems}
                max={treatmentTypeSetting?.maxRequiredContentItems}
                showMaxInDenominator
                count={numTotalItemsUsed}
                contentTypes={treatmentTypeSetting?.contentTypes}
                data-testid="page-module-item-count"
              />
            </div>
          )}
        </Droppable>
      </Wrapper>
      {index !== undefined && isAvailableLayoutModuleType && !isHistory && !isITP && (
        <AddModuleButton
          availableTreatmentTypes={currentLayout?.availableTreatmentTypes as string[]}
          addableUiModules={addableUiModules}
          nextModuleIndex={index + 1}
        />
      )}
    </Wrapper>
  );
};
