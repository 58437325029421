import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { IconArrowDown, IconArrowUp, IconHandle, Wrapper } from '@screentone/core';
import { cloneDeep, isEqual } from 'lodash';

import { Issue } from 'components/datamodel/container/Issue/Issue';
import { IssueSection } from 'components/datamodel/container/Issue/subtypes/IssueSection';
import { List } from 'components/datamodel/container/List/List';
import { BannerModule } from 'components/datamodel/container/Module/BannerModule/BannerModule';
import { Module } from 'components/datamodel/container/Module/Module';
import Newsletter from 'components/datamodel/container/Newsletter/Newsletter';
import OffPlatform from 'components/datamodel/container/OffPlatform/OffPlatform';
import { Page } from 'components/datamodel/container/Page/Page';
import { Section } from 'components/datamodel/container/Section/Section';
import { Article, ArticleProps } from 'components/datamodel/content/Article/Article';
import { Collection } from 'components/datamodel/content/Collection/Collection';
import { CommonContentProps } from 'components/datamodel/content/commonContentProps';
import { LinkedItem as LinkedItemComponent } from 'components/datamodel/content/LinkedItem/LinkedItem';
import { Feed } from 'components/datamodel/content/Query/Feed/Feed';
import { Query } from 'components/datamodel/content/Query/Query';
import { DRAGGABLE_PREFIXES } from 'contexts/drag-and-drop/dragUtils';
import {
  ArticleItem,
  BannerModuleContainer,
  BaseMetadata,
  CollectionDtoMetadata,
  ContentUnion,
  ExternalCollectionItem,
  IssueContainer,
  IssueSectionContainer,
  Layout,
  LinkedItem,
  ListContainer,
  Metadata as TitleModuleMetadata,
  MobileArticleItem,
  ModuleContainer,
  NewsletterPageContainer,
  OffPlatformListContainer,
  OffPlatformMetadata,
  PageContainer,
  PageModule,
  PublicationSettingSearchableContentType,
  QueryItem,
  SectionContainer,
  TreatmentTypeSetting,
  UiModule
} from 'data/generated/graphql';
import { AllessehContent, AllessehContentQueryBody, AllessehQueryRule } from 'hooks/useAllessehContentQuery';
import { isArticleOrMediaContentType } from 'utils/contentType';
// Need to use relative import for stylesheet, otherwise an error will be thrown when running tests
import moduleStyles from '../../components/datamodel/container/Module/Module.module.scss';

export const DataModelType = {
  BannerDTO: 'BannerDTO',
  CollectionDTO: 'CollectionDTO',
  ItpDTO: 'ItpDTO',
  NewsletterDTO: 'NewsletterDTO',
  OffPlatformDTO: 'OffPlatformDTO',
  PageDTO: 'PageDTO'
} as const;

export type DataModelTypeKeys = keyof typeof DataModelType;

export const EntityType = {
  Article: 'Article',
  Collection: 'Collection',
  Issue: 'Issue',
  LinkedItem: 'LinkedItem',
  List: 'List',
  Module: 'Module',
  Page: 'Page',
  Query: 'Query',
  Section: 'Section'
} as const;

export type Container =
  | BannerModuleContainer
  | IssueContainer
  | IssueSectionContainer
  | ListContainer
  | ModuleContainer
  | NewsletterPageContainer
  | OffPlatformListContainer
  | PageContainer
  | SectionContainer;
export type CollectionsType = PageContainer | ModuleContainer | ContentUnion;

export type ContentItem = ArticleItem | ExternalCollectionItem | QueryItem | LinkedItem | MobileArticleItem;
export type EntityComponent = Container | ContentItem;
export type Metadata = BaseMetadata | CollectionDtoMetadata | OffPlatformMetadata;

type ExtraProps = {
  article?: {
    isOverflow?: boolean;
    renderActions?: ArticleProps['renderActions'];
  };
  content?: CommonContentProps;
  module?: {
    layout?: Layout | undefined;
    treatmentTypeSetting?: TreatmentTypeSetting | undefined;
  };
};

type RenderEntityOptions = {
  hierarchyId: string;
  index?: number;
  extraProps?: ExtraProps;
  isHistory?: boolean;
  withoutDragNDrop?: boolean;
};

export interface IDataModelContext<T extends Container> {
  type: DataModelTypeKeys;
  root: T;
  metadata: Metadata;
  hasModelChangedOnce: boolean;
  hasModelChangedRecently: boolean;
  setHasModelChangedRecently: React.Dispatch<React.SetStateAction<boolean>>;
  resetModelChanged: () => void;
  readHierarchyId: {
    (hierarchyId: string): {
      entity: typeof EntityType[keyof typeof EntityType];
      index: number;
    }[];
    (hierarchyId: string, onlyIndices: boolean): number[];
  };
  getAllArticleIds(): string[];
  getAllExternalCollectionIds(): string[];
  getDTO(deleteResolvedFields?: boolean): {
    root: Container;
    metadata: Metadata;
  };
  deleteResolvedFieldsFromExternalDTO(dto: { root: Container; metadata: Metadata }): {
    root: Container;
    metadata: Metadata;
  };
  fromAllessehContent(content: AllessehContent): ArticleItem;
  generateHierarchyId(entity: EntityComponent, parentHierarchyId?: string, index?: number): string;
  generateHierarchyIdFromDragEvent(droppableId: string, dropIndex: number): string;
  setNewRoot(newRoot: Container): void;
  setNewMetadata(newMetadata: Partial<Metadata>): void;
  getEntity<E extends EntityComponent>(hierarchyId: string, options?: { indices?: number[]; root?: E }): E | null;
  swapEntities(srcHierarchyId: string, destHierarchyId: string): void;
  insertEntity(destHierarchyId: string, entity: EntityComponent | EntityComponent[]): void;
  insertEntityToCollection(destHierarchyId: string, entity: ModuleContainer): void;
  modifyEntity(hierarchyId: string, callback: (entity: EntityComponent) => void, updateRoot?: boolean): void;
  changeEntity(hierarchyId: string, callback: (oldEntity: EntityComponent) => EntityComponent): void;
  removeEntity(hierarchyId: string): void;
  removeEntityFromCollection(hierarchyId: string): void;
  removeAllByType(type: string): void;
  getEmbargoContent(): ArticleItem[];
  dedupe(): void;
  renderEntity(entity: EntityComponent, options: RenderEntityOptions): React.ReactElement;
  isEditingLinkedItem: string;
  setIsEditingLinkedItem: React.Dispatch<React.SetStateAction<string>>;
  isItemInDataModel(item: ContentItem): boolean;
  countContent: (entity: EntityComponent) => string;
}

const DEFAULT_STATE: IDataModelContext<Container> = {
  // @ts-expect-error - empty string is not assignable to type DataModelTypeKeys
  type: '',
  // @ts-expect-error - null is not assignable to type Container
  root: null,
  // @ts-expect-error - empty object is not assignable to type BaseMetadata
  metadata: {},
  hasModelChangedOnce: false,
  hasModelChangedRecently: false,
  setHasModelChangedRecently: () => {},
  resetModelChanged: () => {},
  readHierarchyId: () => [],
  getAllArticleIds: () => [],
  getAllExternalCollectionIds: () => [],
  // @ts-expect-error - null is not assignable to root and empty object is not assignable to metadata
  getDTO: () => ({ root: null, metadata: {} }),
  // @ts-expect-error - empty object is not assignable to return type
  deleteResolvedFieldsFromExternalDTO: () => ({}),
  // @ts-expect-error - null is not assignable to type ArticleItem
  fromAllessehContent: () => null,
  generateHierarchyId: () => '',
  generateHierarchyIdFromDragEvent: () => '',
  setNewRoot: () => {},
  setNewMetadata: () => {},
  getEntity: () => null,
  swapEntities: () => {},
  insertEntity: () => {},
  modifyEntity: () => {},
  changeEntity: () => {},
  removeEntity: () => {},
  removeAllByType: () => {},
  getEmbargoContent: () => [],
  dedupe: () => {},
  renderEntity: () => <></>,
  isItemInDataModel: () => false
};

interface DataModelProviderProps {
  children: React.ReactNode;
  metadata: Metadata;
  root: Container;
  setHasItpDTOChanged?: (hasChanged: boolean) => void;
  type: DataModelTypeKeys;
}

export const DataModelContext = createContext(DEFAULT_STATE);

export const DataModelProvider = ({
  children,
  metadata: initialMetadata,
  root: initialRoot,
  setHasItpDTOChanged,
  type
}: DataModelProviderProps) => {
  const [root, setRoot] = useState(initialRoot);
  const [metadata, setMetadata] = useState(initialMetadata);
  const [hasModelChangedOnce, setHasModelChangedOnce] = useState(false);
  const [hasModelChangedRecently, setHasModelChangedRecently] = useState(false);
  const [isEditingLinkedItem, setIsEditingLinkedItem] = useState('');

  const auxRoot = useRef(initialRoot); // auxiliary root to apply successive changes to the root, bypassing React automatic state batching

  function setNewRoot(newRoot: Container) {
    auxRoot.current = newRoot;
    setHasModelChangedRecently(true);
    setRoot(newRoot);
  }

  function isOffPlatformMetadata(metadata: Metadata): metadata is OffPlatformMetadata {
    return 'isArchived' in metadata;
  }

  function setNewMetadata(newMetadata: Partial<Metadata>) {
    setMetadata((prev) => {
      if (isOffPlatformMetadata(prev)) {
        return { ...prev, ...newMetadata } as OffPlatformMetadata;
      }

      return { ...prev, ...newMetadata } as BaseMetadata;
    });
  }

  function resetModelChanged() {
    setHasModelChangedRecently(false);
    setHasModelChangedOnce(false);
  }

  const resetState = useCallback(() => {
    setRoot(initialRoot);
    setMetadata(initialMetadata);
  }, [initialRoot, initialMetadata]);

  function readHierarchyId(
    hierarchyId: string
  ): { entity: typeof EntityType[keyof typeof EntityType]; index: number }[];
  function readHierarchyId(hierarchyId: string, onlyIndices: boolean): number[];
  function readHierarchyId(hierarchyId: string, onlyIndices = false) {
    if (onlyIndices) {
      const stringIndices = hierarchyId.match(/\d+/g);
      if (!stringIndices) {
        throw new Error(`No indices found in hierarchyId: ${hierarchyId}`);
      }

      return stringIndices.map((index) => parseInt(index, 10));
    }

    const splitHierarchyId = hierarchyId.split('_').map((part) => part.split('-'));
    return splitHierarchyId.map(([entity, index]) => {
      // Convert the lowercased entity type to EntityType enum
      const entityType = (entity[0].toUpperCase() + entity.slice(1)) as typeof EntityType[keyof typeof EntityType];
      return { entity: entityType, index: parseInt(index, 10) };
    });
  }

  function generateHierarchyId(entity: EntityComponent, parentHierarchyId?: string, index?: number) {
    let hierarchyId = entity.type.toLowerCase();

    if (parentHierarchyId) {
      hierarchyId = `${parentHierarchyId}_${hierarchyId}`;
    }

    if (index !== undefined) {
      hierarchyId = `${hierarchyId}-${index}`;
    }

    return hierarchyId;
  }

  function generateHierarchyIdFromDragEvent(droppableId: string, dropIndex: number) {
    return `${droppableId}_entity-${dropIndex}`;
  }

  function traverseEntity(
    entity: EntityComponent,
    callback: (entity: EntityComponent, hierarchyId: string) => void,
    parentHierarchyId = '',
    entityIndex = 0
  ) {
    const hierarchyId = generateHierarchyId(entity, parentHierarchyId, entityIndex);
    callback(entity, hierarchyId);

    if ('collection' in entity) {
      entity.collection.forEach((child, i) => traverseEntity(child, callback, hierarchyId, i));
    }
  }

  function getAllArticleIds(): string[] {
    const ids: string[] = [];

    traverseEntity(auxRoot.current, (entity) => {
      if (entity.type === EntityType.Article) {
        const articleItem = entity as ArticleItem;
        ids.push(articleItem.attributes.id);
      }
    });

    return ids;
  }

  function getAllExternalCollectionIds(): string[] {
    const ids: string[] = [];

    traverseEntity(auxRoot.current, (entity) => {
      if (entity.type === EntityType.Collection) {
        const externalCollectionItem = entity as ExternalCollectionItem;
        ids.push(externalCollectionItem.attributes.id);
      }
    });

    return ids;
  }

  function getDTO(deleteResolvedFields = false) {
    const rootCopy = cloneDeep(auxRoot.current);
    const metadataCopy = cloneDeep(metadata);

    traverseEntity(rootCopy, (entity) => {
      if (entity.type === EntityType.Module && deleteResolvedFields) {
        const moduleContainer = entity as ModuleContainer;
        const { pageModule } = moduleContainer.attributes;

        if (pageModule) {
          // TODO: Find a way to make this conversion in the backend, so we don't rely on the frontend to do it
          // The following code is a workaround to convert the values of the `contentTypes` and `allowedMeta` properties into the correct format expected by the backend.
          // This is because the frontend uses the enums from the autogenerated GraphQL types, which are PascalCase,
          // while the backend uses its own enums, which are all lowercase for the `contentTypes` property, and camelCase for the `allowedMeta` property.
          //
          // The following commits are related to this issue:
          // - 642e1971e86f40d4c15a5910345a4cf8d3e7be58 - frontend
          // - 8c0f36d9a7e20404c9bf81b7d78b0a0bac4bff3c - backend
          // ===============================================
          // - 8e92f45c5258168461ddab57aa5a037ae373db03 - backend
          let moduleType = pageModule.uiModuleType.replace(/^Ui|Type$/g, '') as keyof UiModule;
          moduleType = (moduleType[0].toLowerCase() + moduleType.slice(1)) as keyof UiModule;

          const moduleFields = pageModule.uiModuleFields[moduleType];
          if (moduleFields && typeof moduleFields === 'object') {
            if ('contentTypes' in moduleFields) {
              moduleFields.contentTypes = moduleFields.contentTypes.map(
                (contentType) => contentType.toLowerCase() as PublicationSettingSearchableContentType
              );
            }

            if ('allowedMeta' in moduleFields && moduleFields.allowedMeta) {
              moduleFields.allowedMeta = moduleFields.allowedMeta.map(
                (meta) => (meta[0].toLowerCase() + meta.slice(1)) as TitleModuleMetadata
              );
            }
          }
        }
      }

      if (entity.type === EntityType.Article && deleteResolvedFields) {
        const articleItem = entity as ArticleItem;
        delete articleItem.content;
      }

      if (entity.type === EntityType.Collection && deleteResolvedFields) {
        const collectionItem = entity as ExternalCollectionItem;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete collectionItem.contentItems;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete collectionItem.metadata;
      }
    });

    return {
      type,
      root: rootCopy,
      metadata: metadataCopy
    };
  }

  function deleteResolvedFieldsFromExternalDTO(dto: { root: Container; metadata: BaseMetadata }): {
    root: Container;
    metadata: BaseMetadata;
  } {
    const dtoCopy = cloneDeep(dto);
    traverseEntity(dtoCopy.root, (entity) => {
      if (entity.type === EntityType.Article) {
        const articleItem = entity as ArticleItem;
        delete articleItem.content;
      }
      if (entity.type === EntityType.Collection) {
        const collectionItem = entity as ExternalCollectionItem;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete collectionItem.contentItems;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete collectionItem.metadata;
      }
    });
    return dtoCopy;
  }

  function fromAllessehContent(content: AllessehContent): ArticleItem {
    const {
      id: originId,
      type: originContentType,
      attributes: { product, seo_id: seoId, canonical_url: canonicalUrl, source_url: sourceUrl, content_status: status }
    } = content.data;

    if (!isArticleOrMediaContentType(originContentType)) {
      throw new Error(`Unsupported origin content type: ${originContentType}`);
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const normalizedSeoId = seoId ?? '';

    const articleItem: ArticleItem = {
      type: EntityType.Article,
      content: JSON.stringify(content),
      attributes: {
        base_doc_id: originId,
        hosted_url: canonicalUrl || sourceUrl,
        id: originId,
        link: originContentType === 'commerceproduct' ? '' : `api/articles/v1/originid/${originId}`,
        originContentType,
        originId,
        product,
        seo_id: originContentType === 'commerceproduct' ? '' : normalizedSeoId,
        status
      }
    };

    return articleItem;
  }

  function getEntity<E extends EntityComponent>(
    hierarchyId: string,
    { indices, root }: { indices?: number[]; root?: E } = {}
  ): E | null {
    const hierarchyIdSanitized = hierarchyId.replace(/.+?#/, '');
    const hierarchyIdIndices = indices ?? readHierarchyId(hierarchyIdSanitized, true);

    return (
      hierarchyIdIndices.reduce((entity: E | null, index): E | null => {
        if (!entity) return root ?? (auxRoot.current as E);

        if ('collection' in entity) {
          return entity.collection[index] as E;
        }

        return null;
      }, null) ?? null
    );
  }

  function swapEntities(srcHierarchyId: string, destHierarchyId: string) {
    const rootCopy = cloneDeep(auxRoot.current);

    const srcHierarchyIdSanitized = srcHierarchyId.replace(/.+?#/, '');
    const srcHierarchyIdIndices = readHierarchyId(srcHierarchyIdSanitized, true);
    const lastSrcIndex = srcHierarchyIdIndices.pop(); // .at(-1)!;
    const srcEntity = getEntity(srcHierarchyId, { indices: srcHierarchyIdIndices, root: rootCopy });

    if (!('collection' in srcEntity!)) {
      throw new Error('Source entity is not a container');
    }

    const [removed] = srcEntity.collection.splice(lastSrcIndex!, 1);

    const destHierarchyIdSanitized = destHierarchyId.replace(/.+?#/, '');
    const destHierarchyIdIndices = readHierarchyId(destHierarchyIdSanitized, true);
    const lastDestIndex = destHierarchyIdIndices.pop(); // .at(-1)!;
    const destEntity = getEntity(destHierarchyId, { indices: destHierarchyIdIndices, root: rootCopy });

    if (!('collection' in destEntity!)) {
      throw new Error('Destination entity is not a container');
    }

    // @ts-expect-error - splice expect a specific entity type, not a union of entity types
    destEntity.collection.splice(lastDestIndex, 0, removed);

    setNewRoot(rootCopy);
  }

  function isContainer(item: EntityComponent): item is Container {
    return (
      item.type === 'SectionContainer' ||
      item.type === 'PageContainer' ||
      item.type === 'ModuleContainer' ||
      item.type === 'ListContainer' ||
      item.type === 'OffPlatformListContainer'
    );
  }

  const compareItems = (item1: ContentItem, item2: ContentItem) => {
    if (item1.type === item2.type) {
      if ('id' in item1.attributes && 'id' in item2.attributes) {
        return item1.attributes.id === item2.attributes.id;
      }
    }
    return false;
  };

  const existsItemInCollection = (item: ContentItem, collection: Container[]): boolean =>
    collection.some((existItem) => {
      if (isContainer(existItem)) {
        return existsItemInCollection(item, existItem.collection as Container[]);
      }

      return compareItems(item, existItem);
    });

  function isItemInDataModel(item: ContentItem) {
    return existsItemInCollection(item, root.collection as Container[]);
  }

  function insertEntityToCollection(destHierarchyId: string, entity: ModuleContainer) {
    const rootCopy = cloneDeep(root);

    if (!('collection' in rootCopy)) {
      throw new Error('Destination entity is not a container');
    }

    if (rootCopy.collection.length === 0) {
      rootCopy.collection = [entity];
    } else {
      // eslint-disable-next-line no-lonely-if
      if ('collection' in rootCopy.collection[0]) {
        (rootCopy.collection[0].collection as ContentUnion[]).push(...entity.collection);
      }
    }

    setNewRoot(rootCopy);
  }

  function insertEntity(destHierarchyId: string, entity: EntityComponent | EntityComponent[]) {
    const rootCopy = cloneDeep(auxRoot.current);

    const destHierarchyIdSanitized = destHierarchyId.replace(/.+?#/, '');
    const destHierarchyIdIndices = readHierarchyId(destHierarchyIdSanitized, true);
    const lastDestIndex = destHierarchyIdIndices.pop();

    const destEntity = getEntity(destHierarchyId, { indices: destHierarchyIdIndices, root: rootCopy });

    if (!destEntity || !('collection' in destEntity)) {
      throw new Error('Destination entity is not a container');
    }

    if (Array.isArray(entity)) {
      // @ts-expect-error - splice expect a specific entity type, not a union of entity types
      destEntity.collection.splice(lastDestIndex, 0, ...entity);
    } else {
      // @ts-expect-error - splice expect a specific entity type, not a union of entity types
      destEntity.collection.splice(lastDestIndex, 0, entity);
    }

    setNewRoot(rootCopy);
  }

  function modifyEntity(hierarchyId: string, callback: (entity: EntityComponent) => void, updateRoot: boolean = true) {
    const rootCopy = cloneDeep(auxRoot.current);

    const hierarchyIdSanitized = hierarchyId.replace(/.+?#/, '');
    const hierarchyIdIndices = readHierarchyId(hierarchyIdSanitized, true);

    let destEntity: EntityComponent | null = null;
    // eslint-disable-next-line no-restricted-syntax
    for (const index of hierarchyIdIndices) {
      if (!destEntity) {
        destEntity = rootCopy;
      } else {
        destEntity = destEntity.collection[index];
      }
    }

    if (destEntity) {
      callback(destEntity);
    }

    if (updateRoot) {
      setNewRoot(rootCopy);
    }
  }

  function removeEntity(hierarchyId: string) {
    const rootCopy = cloneDeep(auxRoot.current);

    const hierarchyIdSanitized = hierarchyId.replace(/.+?#/, '');
    const hierarchyIdIndices = readHierarchyId(hierarchyIdSanitized, true);

    let destEntity: EntityComponent | null = null;
    for (let i = 0; i < hierarchyIdIndices.length - 1; i++) {
      const index = hierarchyIdIndices[i];

      if (!destEntity) {
        destEntity = rootCopy;
      } else {
        destEntity = destEntity.collection[index];
      }
    }
    const lastDestIndex = hierarchyIdIndices.at(-1)!;

    if (!('collection' in destEntity!)) {
      throw new Error('Destination entity is not a container');
    }

    destEntity.collection.splice(lastDestIndex, 1);

    setNewRoot(rootCopy);
    setHasModelChangedOnce(true);
  }

  function removeEntityFromCollection(hierarchyId: string) {
    const rootClone = cloneDeep(root);

    const index = hierarchyId.match(/_article-(\d+)/)?.[1] ?? null;
    if (index) {
      (rootClone.collection as ModuleContainer[])[0].collection.splice(Number(index), 1);
    }
    setNewRoot(rootClone);
  }

  function removeAllByType(type: string) {
    const entityHierarchyIds: string[] = [];

    traverseEntity(auxRoot.current, (entity, hierarchyId) => {
      if (entity.type === type) {
        entityHierarchyIds.push(hierarchyId);
      }
    });

    entityHierarchyIds.toReversed().forEach((hierarchyId) => removeEntity(hierarchyId));
  }

  function changeEntity(hierarchyId: string, callback: (oldEntity: EntityComponent) => EntityComponent) {
    const rootCopy = cloneDeep(auxRoot.current);

    const hierarchyIdSanitized = hierarchyId.replace(/.+?#/, '');
    const hierarchyIdIndices = readHierarchyId(hierarchyIdSanitized, true);

    let destEntity: EntityComponent | null = null;
    // eslint-disable-next-line no-restricted-syntax
    for (const index of hierarchyIdIndices) {
      if (!destEntity) {
        destEntity = rootCopy;
      } else {
        destEntity = destEntity.collection[index];
      }
    }

    if (destEntity) {
      const newEntity = callback(destEntity);

      removeEntity(hierarchyId);
      insertEntity(hierarchyId, newEntity);
    }
  }

  function getEmbargoContent() {
    const embargoContent: ArticleItem[] = [];

    traverseEntity(auxRoot.current, (entity) => {
      if (entity.type === EntityType.Article) {
        const articleItem = entity as ArticleItem;
        if (articleItem.attributes.status === 'embargo') {
          embargoContent.push(articleItem);
        }
      }
    });

    return embargoContent;
  }

  function dedupe() {
    const rootCopy = cloneDeep(auxRoot.current);
    const allArticleIds = getAllArticleIds();

    traverseEntity(rootCopy, (entity) => {
      if (entity.type === EntityType.Query) {
        const queryItem = entity as QueryItem;
        const queryBody = queryItem.attributes.query as AllessehContentQueryBody;

        const notRules: AllessehQueryRule[] =
          queryBody.query?.not?.filter(
            (item: AllessehQueryRule) => 'term' in item && item.term.key !== 'UpstreamOriginId'
          ) ?? [];

        allArticleIds.forEach((articleId) => {
          notRules.push({
            term: {
              key: 'UpstreamOriginId',
              value: articleId
            }
          });
        });

        if (!queryBody.query) {
          queryBody.query = {};
        }
        queryBody.query.not = notRules;
      }
    });

    setNewRoot(rootCopy);
  }

  function renderEntity(entity: EntityComponent, options: RenderEntityOptions): React.ReactElement {
    const { hierarchyId, index = 0, isHistory = false, withoutDragNDrop } = options;

    let entityComponent: React.ReactNode | null = null;

    if (entity.type === EntityType.Issue) {
      const issue = entity as IssueContainer;
      entityComponent = <Issue data={issue} parentHierarchyId={hierarchyId} index={index} />;
    }

    if (entity.type === EntityType.Section) {
      const section = entity as SectionContainer;
      entityComponent = <Section data={section} parentHierarchyId={hierarchyId} index={index} isHistory={isHistory} />;

      if ('subtype' in entity && entity.subtype === 'IssueSection') {
        entityComponent = (
          <IssueSection
            data={section as IssueSectionContainer}
            parentHierarchyId={hierarchyId}
            index={index}
            isHistory={isHistory}
          />
        );
      }
    }

    if (entity.type === EntityType.Page) {
      const page = entity as PageContainer;
      entityComponent = <Page data={page} parentHierarchyId={hierarchyId} index={index} isHistory={isHistory} />;

      if ('subtype' in entity && entity.subtype === 'NewsletterPage') {
        const newsletter = entity as NewsletterPageContainer;
        entityComponent = (
          <Newsletter
            data={newsletter}
            parentHierarchyId={hierarchyId}
            index={index}
            withoutDragNDrop={withoutDragNDrop}
          />
        );
      }
    }

    if (entity.type === EntityType.Module) {
      const module = entity as ModuleContainer;
      entityComponent = <Module data={module} parentHierarchyId={hierarchyId} index={index} isHistory={isHistory} />;

      if ('subtype' in entity && entity.subtype === 'BannerModule') {
        const bannerModule = entity as BannerModuleContainer;
        entityComponent = (
          <BannerModule data={bannerModule} parentHierarchyId={hierarchyId} index={index} isHistory={isHistory} />
        );
      }
    }

    if (entity.type === EntityType.List) {
      const list = entity as ListContainer;
      entityComponent = <List data={list} parentHierarchyId={hierarchyId} index={index} isHistory={isHistory} />;

      if ('subtype' in entity && entity.subtype === 'OffPlatformList') {
        const offPlatform = entity as OffPlatformListContainer;
        entityComponent = (
          <OffPlatform data={offPlatform} parentHierarchyId={hierarchyId} index={index} isHistory={isHistory} />
        );
      }
    }

    if (entity.type === EntityType.Article) {
      const article = entity as ArticleItem;
      entityComponent = (
        <Article
          data={article}
          parentHierarchyId={hierarchyId}
          index={index}
          isHistory={isHistory}
          {...options.extraProps?.content}
          {...options.extraProps?.article}
        />
      );
    }

    if (entity.type === EntityType.Collection) {
      const collection = entity as ExternalCollectionItem;
      entityComponent = (
        <Collection
          data={collection}
          parentHierarchyId={hierarchyId}
          index={index}
          isHistory={isHistory}
          {...options.extraProps?.content}
        />
      );
    }

    if (entity.type === EntityType.Query) {
      const query = entity as QueryItem;
      if (query.attributes.isFeed) {
        const fullHierarchyId = generateHierarchyId(query, hierarchyId, index);

        entityComponent = (
          <Feed
            data={query}
            isHistory={isHistory}
            hierarchyId={fullHierarchyId}
            {...options.extraProps?.module}
            {...options.extraProps?.content}
          />
        );
      } else {
        entityComponent = (
          <Query
            data={query}
            parentHierarchyId={hierarchyId}
            index={index}
            isHistory={isHistory}
            {...options.extraProps?.content}
          />
        );
      }
    }

    if (entity.type === EntityType.LinkedItem) {
      const linkedItem = entity as LinkedItem;
      entityComponent = (
        <LinkedItemComponent
          data={linkedItem}
          parentHierarchyId={hierarchyId}
          index={index}
          isHistory={isHistory}
          {...options.extraProps?.content}
        />
      );
    }

    if (!entityComponent) {
      throw new Error(`Unknown entity type: ${entity.type}`);
    }

    if (entity.type === EntityType.Module && !isHistory) {
      const module = entity as ModuleContainer;
      const isAvailableLayoutModuleType =
        options.extraProps?.module?.layout?.availableLayoutModules
          .map((m) => m.uiModuleType)
          .includes((module.attributes.pageModule as PageModule).uiModuleType) ?? false;
      const fullHierarchyId = generateHierarchyId(entity, hierarchyId, index);

      if (isAvailableLayoutModuleType && root.type !== EntityType.Issue) {
        entityComponent = (
          <Draggable draggableId={DRAGGABLE_PREFIXES.MODULE + fullHierarchyId} index={index}>
            {((currComponent) =>
              // eslint-disable-next-line func-names
              function (draggableProvided) {
                return (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    className={moduleStyles.draggableModule}
                  >
                    {currComponent}
                    <Wrapper
                      padding={{ all: 'sm' }}
                      {...draggableProvided.dragHandleProps}
                      className={moduleStyles.moduleIconContainer}
                    >
                      <IconArrowUp color="asphalt" />
                      <IconHandle color="asphalt" />
                      <IconArrowDown color="asphalt" />
                    </Wrapper>
                  </div>
                );
              })(entityComponent)}
          </Draggable>
        );
      }
    }

    // Wrap Article, Collection, and Query entities in Draggable
    if (
      (entity.type === EntityType.Article ||
        entity.type === EntityType.Collection ||
        entity.type === EntityType.Query ||
        entity.type === EntityType.LinkedItem) &&
      !withoutDragNDrop
    ) {
      const fullHierarchyId = generateHierarchyId(entity, hierarchyId, index);
      const draggablePrefix = isHistory ? DRAGGABLE_PREFIXES.HISTORY_MODULE_ITEM : DRAGGABLE_PREFIXES.MODULE_ITEM;
      let draggableId = draggablePrefix + fullHierarchyId;
      if (isHistory) {
        draggableId = draggablePrefix + JSON.stringify(entity);
      }

      const isDragDisabled = !!(entity.type === EntityType.Query && (entity as QueryItem).attributes.isFeed);

      entityComponent = (
        <Draggable draggableId={draggableId} index={index} isDragDisabled={isDragDisabled}>
          {((currComponent) =>
            // eslint-disable-next-line func-names
            function (draggableProvided) {
              return (
                <div
                  ref={draggableProvided.innerRef}
                  {...draggableProvided.draggableProps}
                  {...draggableProvided.dragHandleProps}
                >
                  {currComponent}
                </div>
              );
            })(entityComponent)}
        </Draggable>
      );
    }

    return entityComponent;
  }

  function countContent(entity: EntityComponent): string {
    let articles = 0;
    let collections = 0;
    let queries = 0;
    let feeds = 0;

    traverseEntity(entity, (contentItem) => {
      if (contentItem.type === 'Article') {
        articles += 1;
      } else if (contentItem.type === 'Collection') {
        collections += 1;
      } else if (contentItem.type === 'Query') {
        const queryItem = contentItem as QueryItem;
        // eslint-disable-next-line no-unused-expressions
        queryItem.attributes.isFeed ? (feeds += 1) : (queries += 1);
      }
    });

    return `${articles} item${articles > 1 ? 's' : ''}${
      collections ? `, ${collections} collection${collections > 1 ? 's' : ''}` : ''
    }${queries ? `, ${queries} quer${queries > 1 ? 'ies' : 'y'}` : ''}${
      feeds ? `, ${feeds} feed${feeds > 1 ? 's' : ''} ` : ''
    }`;
  }

  useEffect(() => {
    setRoot(initialRoot);
    resetModelChanged();
  }, [initialRoot]);

  useEffect(() => {
    setMetadata(initialMetadata);
    resetModelChanged();
  }, [initialMetadata]);

  useEffect(() => {
    const modifiedMetadata = cloneDeep(metadata);
    const modifiedInitialMetadata = cloneDeep(initialMetadata);
    delete modifiedMetadata.publishUtc;
    delete modifiedMetadata.updatedUtc;
    delete modifiedInitialMetadata.publishUtc;
    delete modifiedInitialMetadata.updatedUtc;
    if (!isEqual(root, initialRoot) || !isEqual(modifiedMetadata, modifiedInitialMetadata)) {
      setHasModelChangedRecently(true);
      setHasModelChangedOnce(true);

      if (type === DataModelType.ItpDTO && setHasItpDTOChanged) {
        setHasItpDTOChanged(true);
      }
    } else {
      setHasModelChangedRecently(false);
    }
  }, [root, metadata, initialRoot, initialMetadata, setHasItpDTOChanged, type]);

  useEffect(
    () => () => {
      resetState();
    },
    [resetState]
  );

  const value = {
    type,
    root,
    metadata,
    hasModelChangedOnce,
    hasModelChangedRecently,
    setHasModelChangedRecently,
    resetModelChanged,
    readHierarchyId,
    getAllArticleIds,
    getAllExternalCollectionIds,
    getDTO,
    deleteResolvedFieldsFromExternalDTO,
    fromAllessehContent,
    generateHierarchyId,
    generateHierarchyIdFromDragEvent,
    setNewRoot,
    setNewMetadata,
    getEntity,
    swapEntities,
    insertEntity,
    insertEntityToCollection,
    modifyEntity,
    changeEntity,
    removeEntity,
    removeEntityFromCollection,
    removeAllByType,
    getEmbargoContent,
    dedupe,
    renderEntity,
    isEditingLinkedItem,
    setIsEditingLinkedItem,
    isItemInDataModel,
    countContent
  };

  return <DataModelContext.Provider value={value}>{children}</DataModelContext.Provider>;
};
