/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import type { ContentStatusFilterType } from 'components/search-content/components/search-content-filters/components/status-filter/StatusFilter';
import { PublicationSetting, PublicationSettingSearchableContentType } from 'data/generated/graphql';
import { AllessehQueryRuleTerm, AllessehQueryRuleTerms } from 'hooks/useAllessehContentQuery';

export const getContentLabel = (type: string) => {
  switch (type) {
    case PublicationSettingSearchableContentType.CommerceProduct.toLowerCase():
    case PublicationSettingSearchableContentType.CommerceProduct:
      return 'Product';
    case PublicationSettingSearchableContentType.LiveCoverage.toLowerCase():
    case PublicationSettingSearchableContentType.LiveCoverage:
      return 'Live Coverage';
    case PublicationSettingSearchableContentType.LiveCoverageCard.toLowerCase():
    case PublicationSettingSearchableContentType.LiveCoverageCard:
      return 'Live Coverage card';
    case PublicationSettingSearchableContentType.LiveCoverageStack.toLowerCase():
    case PublicationSettingSearchableContentType.LiveCoverageStack:
      return 'Live Coverage event';
    case PublicationSettingSearchableContentType.DowJonesRiskJournalCcoEvents.toLowerCase():
    case PublicationSettingSearchableContentType.DowJonesRiskJournalCcoEvents:
      return 'Dow Jones Risk Journal CCO Events';
    case PublicationSettingSearchableContentType.DowJonesRiskJournalContributor.toLowerCase():
    case PublicationSettingSearchableContentType.DowJonesRiskJournalContributor:
      return 'Dow Jones Risk Journal Contributor';
    case PublicationSettingSearchableContentType.DowJonesRiskJournalDjEvents.toLowerCase():
    case PublicationSettingSearchableContentType.DowJonesRiskJournalDjEvents:
      return 'Dow Jones Risk Journal DJ Events';
    case PublicationSettingSearchableContentType.DowJonesRiskJournal.toLowerCase():
    case PublicationSettingSearchableContentType.DowJonesRiskJournal:
      return 'Dow Jones Risk Journal';
    case PublicationSettingSearchableContentType.Wsj.toLowerCase():
    case PublicationSettingSearchableContentType.Wsj:
      return 'WSJ';
    default:
      return type;
  }
};

export const getStatusLabel = (status: ContentStatusFilterType): string => {
  const LABELS: Record<ContentStatusFilterType, string> = {
    PUBLISHED: 'Published',
    EMAIL_ONLY: 'Email Only',
    EMBARGO: 'Embargo',
    SCHEDULED_PUBLISH: 'Scheduled Publish'
  };

  return LABELS[status];
};

const addToMergedQuery = (clauses: AllessehQueryRuleTerms[], key: string, value: string | string[]) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const existingClause = clauses.find((clause) => clause.terms && clause.terms.key === key);
  if (existingClause) {
    if (Array.isArray(value)) {
      value.forEach((val) => {
        if (existingClause.terms.value.includes(val)) return;

        existingClause.terms.value.push(val);
      });
    } else {
      if (existingClause.terms.value.includes(value)) {
        return;
      }
      existingClause.terms.value.push(value);
    }
  } else if (Array.isArray(value)) {
    clauses.push({
      terms: {
        key,
        value
      }
    });
  } else {
    clauses.push({
      terms: {
        key,
        value: [value]
      }
    });
  }
};

function mergeQueries(queries: string[]) {
  const mergedQuery = {
    query: {
      and: [],
      not: []
    }
  };
  queries.forEach((queryString) => {
    if (!queryString) return;
    const { query } = JSON.parse(queryString) as { query: { and?: []; not?: [] } };
    if (query.and) {
      query.and.forEach((clause: AllessehQueryRuleTerm | AllessehQueryRuleTerms) => {
        let key;
        let value;

        if ('term' in clause) {
          ({ key, value } = clause.term);
        } else {
          ({ key, value } = clause.terms);
        }
        addToMergedQuery(mergedQuery.query.and, key, value);
      });
    }
    if (query.not) {
      query.not.forEach((clause: AllessehQueryRuleTerm | AllessehQueryRuleTerms) => {
        let key;
        let value;

        if ('term' in clause) {
          ({ key, value } = clause.term);
        } else {
          ({ key, value } = clause.terms);
        }

        addToMergedQuery(mergedQuery.query.not, key, value);
      });
    }
  });
  return mergedQuery;
}

enum BaseQueryType {
  BASE_ALLESSEH_QUERY = 'baseAllessehQuery',
  BASE_ALLESSEH_PRODUCT_CARD_QUERY = 'baseProductCardAllessehQuery',
  BASE_ALLESSEH_LIVE_COVERAGE_QUERY = 'baseLiveCoverageAllessehQuery',
  BASE_TAPPABLE_ALLESSEH_QUERY = 'baseTappableAllessehQuery',
  BASE_AUDIO_ALLESSEH_QUERY = 'baseAudioAllessehQuery',
  BASE_VIDEO_ALLESSEH_QUERY = 'baseVideoAllessehQuery',
  BASE_PODCAST_ALLESSEH_QUERY = 'basePodcastAllessehQuery',
  BASE_RECIPE_ALLESSEH_QUERY = 'baseRecipeAllessehQuery',
  BASE_WEBSTORY_ALLESSEH_QUERY = 'baseWebStoryAllessehQuery',
  BASE_OPINION_ALLESSEH_QUERY = 'baseOpinionAllessehQuery',
  BASE_MARKETWATCH_ALLESSEH_QUERY = 'baseMarketwatchAllessehQuery',
  BASE_PENTA_ALLESSEH_QUERY = 'basePentaAllessehQuery',
  BASE_AFP_ALLESSEH_QUERY = 'baseAfpAllessehQuery',
  BASE_WSJ_ALLESSEH_QUERY = 'baseWsjAllessehQuery',
  BASE_MANSION_GLOBAL_ALLESSEH_QUERY = 'baseMansionGlobalAllessehQuery',
  BASE_BARRONS_ALLESSEH_QUERY = 'baseBarronsAllessehQuery',
  BASE_DOW_JONES_RISK_JOURNAL_DJ_EVENTS_ALLESSEH_QUERY = 'baseDowJonesRiskJournalDJEventsAllessehQuery',
  BASE_DOW_JONES_RISK_JOURNAL_CCO_EVENTS_ALLESSEH_QUERY = 'baseDowJonesRiskJournalCCOEventsAllessehQuery',
  BASE_DOW_JONES_RISK_JOURNAL_CONTRIBUTOR_ALLESSEH_QUERY = 'baseDowJonesRiskJournalContributorAllessehQuery'
}

const CONTENT_TYPE_MAP = {
  Article: BaseQueryType.BASE_ALLESSEH_QUERY,
  CommerceProduct: BaseQueryType.BASE_ALLESSEH_PRODUCT_CARD_QUERY,
  Audio: BaseQueryType.BASE_AUDIO_ALLESSEH_QUERY,
  Video: BaseQueryType.BASE_VIDEO_ALLESSEH_QUERY,
  LiveCoverage: BaseQueryType.BASE_ALLESSEH_LIVE_COVERAGE_QUERY,
  LiveCoverageCard: BaseQueryType.BASE_ALLESSEH_LIVE_COVERAGE_QUERY,
  LiveCoverageStack: BaseQueryType.BASE_ALLESSEH_LIVE_COVERAGE_QUERY,
  Tappable: BaseQueryType.BASE_TAPPABLE_ALLESSEH_QUERY,
  Podcast: BaseQueryType.BASE_PODCAST_ALLESSEH_QUERY,
  Recipe: BaseQueryType.BASE_RECIPE_ALLESSEH_QUERY,
  Webstory: BaseQueryType.BASE_WEBSTORY_ALLESSEH_QUERY,
  Opinion: BaseQueryType.BASE_OPINION_ALLESSEH_QUERY,
  Marketwatch: BaseQueryType.BASE_MARKETWATCH_ALLESSEH_QUERY,
  Penta: BaseQueryType.BASE_PENTA_ALLESSEH_QUERY,
  MansionGlobal: BaseQueryType.BASE_MANSION_GLOBAL_ALLESSEH_QUERY,
  Afp: BaseQueryType.BASE_AFP_ALLESSEH_QUERY,
  Wsj: BaseQueryType.BASE_WSJ_ALLESSEH_QUERY,
  Barrons: BaseQueryType.BASE_BARRONS_ALLESSEH_QUERY,
  RiskNews: BaseQueryType.BASE_ALLESSEH_QUERY,
  DowJonesRiskJournal: BaseQueryType.BASE_ALLESSEH_QUERY,
  DowJonesRiskJournalDJEvents: BaseQueryType.BASE_DOW_JONES_RISK_JOURNAL_DJ_EVENTS_ALLESSEH_QUERY,
  DowJonesRiskJournalCCOEvents: BaseQueryType.BASE_DOW_JONES_RISK_JOURNAL_CCO_EVENTS_ALLESSEH_QUERY,
  DowJonesRiskJournalContributor: BaseQueryType.BASE_DOW_JONES_RISK_JOURNAL_CONTRIBUTOR_ALLESSEH_QUERY
};

export enum BaseQueryOrigin {
  SEARCH = 'search',
  QUERY_BUILDER = 'query-builder'
}

interface getBaseQueryProps {
  contentTypes?: PublicationSettingSearchableContentType[];
  publicationSettings?: PublicationSetting;
  origin: BaseQueryOrigin;
}

export const getBaseQuery = ({ contentTypes, publicationSettings, origin }: getBaseQueryProps) => {
  if (publicationSettings && contentTypes) {
    // fallback to baseQuery if content types are not specified.
    const queries =
      origin === BaseQueryOrigin.QUERY_BUILDER
        ? [publicationSettings[BaseQueryType.BASE_ALLESSEH_QUERY]]
        : contentTypes.reduce((queries: string[], contentType: PublicationSettingSearchableContentType) => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (contentType in CONTENT_TYPE_MAP) {
              const key = contentType as keyof typeof CONTENT_TYPE_MAP;
              queries.push(publicationSettings[CONTENT_TYPE_MAP[key]] as string);
            }
            return queries;
          }, []);
    const mergedBaseAllessehQuery = mergeQueries(queries);
    return JSON.stringify(mergedBaseAllessehQuery);
  }
  return '{}';
};

/**
 * Util function to check if the content type of an Allesseh item is an article or media type.
 * @param contentType The content type of the Allesseh item
 */
export const isArticleOrMediaContentType = (contentType: string) => {
  const articleOrMediaContentTypes = [
    'article',
    'card',
    'bulletin',
    'pulse',
    'audio',
    'video',
    'podcast',
    'sponsored',
    'webstory',
    'commerceproduct',
    'recipe'
  ];

  return articleOrMediaContentTypes.includes(contentType.toLowerCase());
};
